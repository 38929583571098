var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center text-black"
  }, [_vm.payment === 90000 ? _c('span', {
    staticClass: "badge p-2 badge-pill"
  }, [_c('img', {
    staticClass: "icon",
    attrs: {
      "src": require("../../assets/img/dollar.png"),
      "alt": "",
      "srcset": ""
    }
  }), _vm._v(" PAID ")]) : _vm._e(), _vm.payment === 50000 ? _c('span', {
    staticClass: "badge p-2 badge-pill"
  }, [_c('i', {
    staticClass: "fa fa-times text-danger",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" CANCEL ")]) : _vm._e(), _vm.payment === 50001 ? _c('span', {
    staticClass: "badge p-2 badge-pill"
  }, [_c('i', {
    staticClass: "fa fa-times text-danger",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" ERROR ")]) : _vm._e(), _vm.payment === 50002 ? _c('span', {
    staticClass: "badge p-2 badge-pill"
  }, [_c('i', {
    staticClass: "fa fa-times text-danger",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" DELIVERY ERROR ")]) : _vm._e(), _vm.payment === 50004 ? _c('span', {
    staticClass: "badge p-2 badge-pill"
  }, [_c('i', {
    staticClass: "fa fa-stop",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" EXPIRE ")]) : _vm._e(), _vm.payment === 10001 ? _c('span', {
    staticClass: "badge p-2 badge-pill"
  }, [_c('img', {
    staticClass: "icon",
    attrs: {
      "src": require("../../assets/img/coins.png"),
      "alt": "",
      "srcset": ""
    }
  }), _vm._v(" New ")]) : _vm._e(), _vm.payment === 20001 ? _c('span', {
    staticClass: "badge p-2 badge-pill"
  }, [_c('img', {
    staticClass: "icon",
    attrs: {
      "src": require("../../assets/img/money.png"),
      "alt": "",
      "srcset": ""
    }
  }), _vm._v(" Waiting Payment ")]) : _vm._e(), _vm.payment === 20002 ? _c('span', {
    staticClass: "badge p-2 badge-pill"
  }, [_c('img', {
    staticClass: "icon",
    attrs: {
      "src": require("../../assets/img/money.png"),
      "alt": "",
      "srcset": ""
    }
  }), _vm._v(" PROSES ")]) : _vm._e(), _vm.payment === 40003 ? _c('span', {
    staticClass: "badge p2 badge-pill"
  }, [_c('i', {
    staticClass: "fa fa-repeat",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" REFUND ")]) : _vm._e(), _vm.payment === 50003 ? _c('span', [_c('i', {
    staticClass: "fa fa-ban text-danger",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" DENIED ")]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }