<template>
  <div class="text-center text-black">
    <span v-if="payment === 90000" class="badge p-2 badge-pill">
      <img src="../../assets/img/dollar.png" class="icon" alt="" srcset="" />
      PAID
    </span>
    <span v-if="payment === 50000" class="badge p-2 badge-pill">
      <i class="fa fa-times text-danger" aria-hidden="true"></i> CANCEL
    </span>
    <span v-if="payment === 50001" class="badge p-2 badge-pill">
      <i class="fa fa-times text-danger" aria-hidden="true"></i> ERROR
    </span>
    <span v-if="payment === 50002" class="badge p-2 badge-pill">
      <i class="fa fa-times text-danger" aria-hidden="true"></i> DELIVERY ERROR
    </span>
    <span v-if="payment === 50004" class="badge p-2 badge-pill">
      <i class="fa fa-stop" aria-hidden="true"></i> EXPIRE
    </span>
    <span v-if="payment === 10001" class="badge p-2 badge-pill">
      <img src="../../assets/img/coins.png" class="icon" alt="" srcset="" /> New
    </span>
    <span v-if="payment === 20001" class="badge p-2 badge-pill">
      <img src="../../assets/img/money.png" class="icon" alt="" srcset="" />
      Waiting Payment
    </span>
    <span v-if="payment === 20002" class="badge p-2 badge-pill">
      <img src="../../assets/img/money.png" class="icon" alt="" srcset="" />
      PROSES
    </span>
    <span v-if="payment === 40003" class="badge p2 badge-pill">
      <i class="fa fa-repeat" aria-hidden="true"></i>
      REFUND
    </span>
    <span v-if="payment === 50003">
      <i class="fa fa-ban text-danger" aria-hidden="true"></i> DENIED
    </span>
  </div>
</template>

<script>
export default {
  name: 'payment',
  props: {
    payment: {
      type: Number,
      required: true,
    },
  },
};
</script>
<style scoped>
.icon {
  width: 17px;
}
.badge {
  color: black;
}
</style>
